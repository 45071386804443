import { template as template_b7084b67c57948d58114e9c53141da5e } from "@ember/template-compiler";
const FKControlMenuContainer = template_b7084b67c57948d58114e9c53141da5e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
