import { template as template_dba297c5ab5f42319bdd33d3cf54c52d } from "@ember/template-compiler";
const FKText = template_dba297c5ab5f42319bdd33d3cf54c52d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
